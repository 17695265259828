import React from "react";
import { Typography, makeStyles, Container } from "@material-ui/core";
import { useStaticQuery, graphql } from "gatsby";
import { Tabs, Tab, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2rem",
    minHeight: "50vh",
    marginTop: "2rem",
    background: theme.palette.primary.main,
  },
  tabRoot: {
    marginTop: "3rem",
  },
  tab: {
    border: "none",
  },
  tabsContainer: {
    justifyContent: "space-between",
    transition: "1s",
  },
  tabSelected: {
    background: "rgba(255, 255, 255, 0.3)",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    transition: "0.5s",
    msTransitionTimingFunction: "ease-in-out",
  },
  indicator: {
    backgroundColor: "transparent",
  },
  box: {
    border: "none",
  },
  text: {
    color: theme.palette.primary.light,
    borderTop: "5px solid #fff",
  },
  title: {
    fontSize: "clamp(2rem, 6vw, 4rem)",
    color: theme.palette.primary.light,
  },
  titles: {
    fontSize: "clamp(1.1rem, 3vw, 1rem)",
    color: theme.palette.primary.light,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Box
          style={{
            borderBottomLeftRadius: 8,
            borderBottomRightRadius: 8,
            background: "transparent",
          }}
          p={3}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const getCardDescriptions = (data) =>
  data?.allCardDescriptionsJson?.edges || [];

const getCardDescription = (services) => ({
  ...services?.node,
});

const Beschreibung = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const data = useStaticQuery(graphql`
    query {
      allCardDescriptionsJson {
        edges {
          node {
            id
            title
            icon
            description
            showDescription
          }
        }
      }
    }
  `);

  const cards = getCardDescriptions(data).map((service) =>
    getCardDescription(service)
  );

  return (
    <>
      <div className={classes.root}>
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            gutterBottom
            align="center"
            className={classes.title}
          >
            Erfahren Sie mehr
          </Typography>
          <div className={classes.tabRoot}>
            <Tabs
              disableRipple
              value={value}
              onChange={(event, newValue) => setValue(newValue)}
              //TabIndicatorProps={{classes}}
              classes={{
                flexContainer: classes.tabsContainer,
                indicator: classes.indicator,
              }}
              className={classes.tab}
            >
              {cards.map((card, idx) => (
                <Tab
                  disableRipple
                  label={
                    <Box className={classes.box}>
                      <Typography className={classes.titles}>
                        {card.title}
                      </Typography>
                    </Box>
                  }
                  classes={{ selected: classes.tabSelected }}
                  className={classes.tab}
                />
              ))}
            </Tabs>

            {cards.map((card, idx) => (
              <TabPanel value={value} index={idx} className={classes.text}>
                <Typography variant="h6">{card.description}</Typography>
              </TabPanel>
            ))}
          </div>
          <div id="faq"></div>
        </Container>
      </div>
    </>
  );
};

export default Beschreibung;
