import React from "react";
import { Typography, makeStyles, Container, Grid } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.light,
    paddingTop: "2rem",
    paddingBottom: "2rem",
  },

  iconGrid: {
    margin: "2rem 0rem 2rem 0rem",
  },
  icons: {
    fontSize: "1.5rem",
    marginRight: ".3rem",
  },
  content: {
    display: "flex",
    marginBottom: "1rem",
    fontSize: "clamp(0.8rem, 1.5vw, 1.2rem)",
  },
  subtitle: {
    fontSize: "clamp(0.8rem, 1.5vw, 1.3rem)",
  },
  title: {
    fontSize: "clamp(2rem, 6vw, 4rem)",
  },
}));

const Vorteile = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container>
        <Typography
          variant="h2"
          gutterBottom
          align="center"
          className={classes.title}
        >
          THG-Quotenservice
        </Typography>
        <Typography
          variant="h6"
          gutterBottom
          align="center"
          className={classes.subtitle}
        >
          Mit dem THG-Quotenservice ganz einfach Erlöse durch Ihre rein
          batterieelektrischen Fahrzeuge generieren. M3E übernimmt die
          Komplettabwicklung und Sie profitieren von jahrelanger Erfahrung und
          Expertise.
        </Typography>
        <Container maxWidth="md">
          <Grid container className={classes.iconGrid}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6" className={classes.content}>
                <CheckCircleOutlineIcon className={classes.icons} />
                jährliche Prämie mit Ihrem Elektrofahrzeug sichern
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6" className={classes.content}>
                <CheckCircleOutlineIcon className={classes.icons} />
                einfache Abwicklung und kompetente Beratung durch M3E
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6" className={classes.content}>
                <CheckCircleOutlineIcon className={classes.icons} />
                Planungssicherheit dank Festpreisangebot
              </Typography>
            </Grid>
            <div id="dienstleistungen"></div>
          </Grid>
        </Container>
      </Container>
    </div>
  );
};

export default Vorteile;
