import React, { useEffect, useState } from "react";
import { Typography, makeStyles, Button } from "@material-ui/core";
import publicVideoSrc from "../../media/videos/video.mp4";
import stellantisVideoSrc from "../../media/videos/stellantis.mp4";
import Video from "../Video";
import Link from "../Link";
import { lighten } from "@material-ui/core/styles";
import HeroBanner from "./HeroBanner";

import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  heroContainer: {
    background: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    padding: "0 1rem",
    position: "relative",
    color: theme.palette.primary.light,
  },
  btn: {
    color: theme.palette.primary.light,
    background: theme.palette.primary.main,
    transition: "0.5s",
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
  heroBg: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  videoBg: {
    width: "100%",
    height: "100%",
    filter: "brightness(0.7)",
  },
  heroContent: {
    zIndex: 3,
    height: "calc(100vh - 80px)",
    maxHeight: "100%",
    padding: "0rem calc((100vw - 1300px) /2)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (orientation: landscape)": {
      paddingTop: "7rem",
      paddingBottom: "3rem",
    },
  },
  heroItems: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    maxHeight: "100%",
    padding: "0",
    color: "#fff",
    lineHeight: "1.1",
    fontWeight: "bold",
  },
  h1: {
    fontSize: "clamp(2.2rem, 6vw, 4rem)",
    marginBottom: "1.5rem",
    marginTop: "3rem",
    letterSpacing: "3px",
    padding: "0 1rem",
  },
  h5: {
    fontSize: "clamp(1rem, 3vw, 2rem)",
    marginBottom: "2rem",
  },
}));

const getSrc = () => {
  switch (process.env.GATSBY_BRANDING) {
    case "stellantis":
      return stellantisVideoSrc;
    case "public":
      return publicVideoSrc;
    default:
      return undefined;
  }
};

const HeroTest = () => {
  const classes = useStyles();
  const src = getSrc();
  const prices = useSelector((state) => state.prices.items);
  const [m1Price, setM1Price] = useState(false);
  useEffect(() => {
    const newM1Price = (prices || []).find((p) => p.type === "m1");
    if (newM1Price?.price) setM1Price(newM1Price.price);
  }, [prices, m1Price]);
  const year = new Date().getFullYear();

  return (
    <div className={classes.heroContainer}>
      <div className={classes.heroBg}>
        <HeroBanner />
        <Video className={classes.videoBg} src={src} type="video/mp4" />
      </div>
      <div className={classes.heroContent}>
        <div className={classes.heroItems}>
          <Typography variant="h1" gutterBottom className={classes.h1}>
            Zusatzerlöse mit Ihrem Elektrofahrzeug
          </Typography>
          <Typography variant="h5" gutterBottom className={classes.h5}>
            Lassen Sie sich für Ihre CO2-Ersparnis entlohnen und sichern Sie
            sich bereits jetzt für <b>{year}</b> eine Prämie von bis zu{" "}
            <b>{m1Price} €</b> für Ihren E-PKW.
          </Typography>
          <Button
            variant="contained"
            size="large"
            href="/register"
            component={Link}
            className={classes.btn}
          >
            Jetzt Registrieren
          </Button>
          <div id="vorteile"></div>
        </div>
      </div>
    </div>
  );
};

export default HeroTest;
