import React from "react";

const HeroBanner = () => {
  return (
    <div className="mt-20 flex items-center gap-x-6 bg-orange-400 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p className="text-sm leading-6 text-white">
        <strong>HINWEIS</strong>: Leider können wir keine Fahrzeuge mehr für
        2024 annehmen. Wir arbeiten aber daran, dass Sie in Kürze Ihre Fahrzeuge
        schon wieder für 2025 anmelden können. &nbsp;
      </p>
      <div className="flex flex-1 justify-end" />
    </div>
  );
};

export default HeroBanner;
